<script>
  import { onMount } from 'svelte'
  import { Icon } from '@qlean/ui-kit-web-svelte'
  import { Bus } from '@qlean/web-core'
  import classNames from 'classnames/bind'

  import css from './styles.scss'

  import {
    Auth,
    Cleaning,
    Wola,
    Drycleaning,
    Windows,
    DrycleaningLamoda,
    QleanBox,
    DrycleaningShoes,
    CleaningOffice,
    Conditioner,
    Callback,
  } from './forms'
  import SellGoodsShortWeb from './forms/SellGoodsShortWeb/index.svelte'

  export let type
  export let productSlug
  export let pageSlug
  export let elemId
  export let fields
  export let price

  const cN = classNames.bind(css)

  const defaultValues = Object.entries(fields).reduce((values, [fieldName, fieldValue]) => {
    return {
      ...values,
      [fieldName]:
        fieldValue === undefined
          ? fieldValue
          : fieldValue.initialValue !== undefined
          ? fieldValue.initialValue
          : fieldValue,
    }
  }, {})

  onMount(() => {
    Bus.emit('FORM_INITIALIZE')
  })

  const isCleaning =
    productSlug === 'cleaning_flat_standard' ||
    productSlug === 'cleaning_flat_deep' ||
    productSlug === 'cleaning_flat_renovation' ||
    productSlug === 'cleaning_house_deep'
  // productSlug === 'cleaning_office_standard'
</script>

{@html Icon.spriteContent}

<div id={elemId}>
  {#if type === 'order'}
    {#if productSlug === 'water'}
      <Wola {...$$restProps} {fields} {productSlug} {defaultValues} {price} {cN} />
    {:else if isCleaning}
      <Cleaning {...$$restProps} {productSlug} {fields} {defaultValues} {cN} />
    {:else if productSlug === 'cleaning_office_standard'}
      <CleaningOffice {...$$restProps} {productSlug} {fields} {defaultValues} {cN} />
    {:else if productSlug === 'cleaning_air_conditioner'}
      <Conditioner {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'drycleaning' && pageSlug !== '/drycleaning/lamoda'}
      <Drycleaning {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'plt_drycleaning_carpet' && pageSlug !== '/drycleaning/lamoda'}
      <Drycleaning {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'drycleaning_furniture' && pageSlug !== '/drycleaning/lamoda'}
      <Drycleaning {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'plt_drycleaning_curtains' && pageSlug !== '/drycleaning/lamoda'}
      <Drycleaning {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'laundry_ondemand' && pageSlug !== '/drycleaning/lamoda'}
      <Drycleaning {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'drycleaning_shoes'}
      <DrycleaningShoes {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'drycleaning' && pageSlug === '/drycleaning/lamoda'}
      <DrycleaningLamoda {...$$restProps} {productSlug} {pageSlug} {defaultValues} {cN} />
    {:else if productSlug === 'cleaning_flat_windows'}
      <Windows {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'sell_goods' && pageSlug === '/market/qlean-box'}
      <QleanBox {...$$restProps} {productSlug} {defaultValues} {cN} />
    {:else if productSlug === 'sell_goods_new' && pageSlug === '/market/qlean-box/form'}
      <SellGoodsShortWeb {...$$restProps} {productSlug} {defaultValues} {cN} />
    {/if}
  {:else if type === 'auth'}
    <Auth {...$$restProps} {cN} />
  {:else if type === 'callback'}
    <Callback {...$$restProps} {cN} />
  {/if}
</div>
